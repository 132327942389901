exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-coverage-checker-tsx": () => import("./../../../src/templates/coverage-checker.tsx" /* webpackChunkName: "component---src-templates-coverage-checker-tsx" */),
  "component---src-templates-help-tsx": () => import("./../../../src/templates/help.tsx" /* webpackChunkName: "component---src-templates-help-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-our-deals-tsx": () => import("./../../../src/templates/our-deals.tsx" /* webpackChunkName: "component---src-templates-our-deals-tsx" */),
  "component---src-templates-our-story-tsx": () => import("./../../../src/templates/our-story.tsx" /* webpackChunkName: "component---src-templates-our-story-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

