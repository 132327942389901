/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'
import { ThemeProvider } from 'theme-ui'
import { theme } from './src/styles/theme'
import { YouTubeProvider } from './src/context/youtube'

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <YouTubeProvider>{element}</YouTubeProvider>
  </ThemeProvider>
)

export const shouldUpdateScroll = ({ routerProps }) => {
  if (!routerProps.location.hash) {
    window.scroll({
      top: 0,
      left: 0,
    })
    return false
  }
}
